import { ThemeProvider } from '@emotion/react';
import { Wax } from '@eosdacio/ual-wax';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Anchor } from 'ual-anchor';
import { UALProvider } from 'ual-reactjs-renderer';

import GlobalStyles from '@styles/global';
import theme from '@styles/theme';
import { WAX_SIGN_IN } from '@utils/globals';
import { STRINGS } from '@utils/strings';
import App from './App';
import reportWebVitals from './reportWebVitals';

const waxChain = {
    chainId: process.env.REACT_APP_WAX_CHAINID,
    rpcEndpoints: [
        {
            protocol: process.env.REACT_APP_WAX_PROTOCOL,
            host: process.env.REACT_APP_WAX_HOST,
            port: process.env.REACT_APP_WAX_PORT
        }
    ]
};

const appName = STRINGS.campaign;
const anchor = new Anchor([waxChain], { appName: appName });
const waxcloud = new Wax([waxChain], { appName: appName });

const domNode = document.getElementById('root');
const root = createRoot(domNode);

root.render(
    <StrictMode>
        <ThemeProvider theme={theme}>
            <GlobalStyles />
            {WAX_SIGN_IN ? (
                <UALProvider
                    chains={[waxChain]}
                    authenticators={[ waxcloud, anchor ]}
                    appName={appName}
                >
                    <App />
                </UALProvider>
            ) : (
                <App />
            )}
        </ThemeProvider>
    </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
