import styled from '@emotion/styled';

import WithRouter from '@components/WithRouter';

const CustomLink = styled.a(({ theme, header, tab, isActive }) => {
    let typography = header ? theme.typography.h5 : theme.typography.p;
    let headerStyle = header
        ? {
            marginLeft: 0,
            marginBottom: theme.spacing.s,
            [theme.mediaQuery.tabletLandscapeUp]: {
                marginBottom: 0
            }
        }
        : {};
    let padding = tab
        ? {
            padding: `${theme.spacing.xs} ${theme.spacing.s}`
        }
        : {};

    return {
        ...typography,
        color: isActive() ? theme.colors.primary.light : theme.colors.primary.main,
        borderBottom: isActive()
            ? `solid ${theme.colors.primary.light} 3px`
            : 'none',
        textDecoration: 'none',
        whiteSpace: 'nowrap',
        opacity: 1,
        '&:hover': {
            color: theme.colors.primary.light,
            cursor: 'pointer'
        },
        ...padding,
        ...headerStyle
    };
});

function Link({
    href,
    children,
    header,
    tab,
    activeWhen,
    onClick,
    match,
    history,
    location,
    style
}) {
    function isActive() {
        if (href && location) {
            return location.pathname.includes(href.slice(2));
        }
    }

    return (
        <CustomLink
            href={href}
            header={header}
            tab={tab}
            isActive={isActive}
            onClick={onClick}
        >
            {children}
        </CustomLink>
    );
}

export default WithRouter(Link);
