import styled from '@emotion/styled';
import { Route, Routes } from 'react-router-dom';
import { withUAL } from 'ual-reactjs-renderer';

import WithRouter from '@components/WithRouter';

import FiatHistory from '@components/Fiat/FiatHistory';
import ActOne from '@context/ActOne';
import ActTwo from '@context/ActTwo';
import Craft from '@context/Craft';
import FAQ from '@context/FAQ';
import Home from '@context/Home';
import Inventory from '@context/Inventory';
import Leaderboard from '@context/Leaderboard';
import License from '@context/License';
import News from '@context/News';
import NotFound from '@context/NotFound';
import PackRips from '@context/PackRips';
import PrivacyPolicy from '@context/PrivacyPolicy';
import Resources from '@context/Resources';
import BuyPacks from '@context/Shop/BuyPacks';
import TermsAndConditions from '@context/TermsAndConditions';
import VivaPacks from '@context/VivaPacks';

import {
    FIAT_PAYMENT_ENABLED,
    SHOW_BUY_PAGE,
    SHOW_CRAFT,
    SHOW_FAQ,
    SHOW_HEADER,
    SHOW_NEWS,
    SHOW_PACK_HISTORY,
    WAX_SIGN_IN
} from '@utils/globals';
import ROUTES from '@utils/routes';

const Container = styled.div(({ theme }) => ({
    width: '100%',
    height: '100%',
    margin: SHOW_HEADER ? `${theme.size.headerHeight} 0px 0px 0px` : 0
}));

function Content({ ual, location, history }) {
    if (WAX_SIGN_IN && ual && !ual.activeUser) {
        if (
            location &&
      (location.pathname.includes(ROUTES.inventory) ||
        location.pathname.includes(ROUTES.craft) ||
        location.pathname.includes(ROUTES.view))
        ) {
            history.push(ROUTES.home);
        }
    }

    return (
        <Container>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path={ROUTES.home} element={<Home />} />
                {SHOW_NEWS ? <Route path={ROUTES.news} element={<News />} /> : null}
                {SHOW_BUY_PAGE ? (
                    <Route path={ROUTES.buypacks} element={<BuyPacks />} />
                ) : null}
                {SHOW_FAQ ? <Route path={ROUTES.faq} element={<FAQ />} /> : null}
                {WAX_SIGN_IN ? (
                    <Route path={ROUTES.inventory} element={<Inventory />} />
                ) : null}
                {WAX_SIGN_IN && SHOW_PACK_HISTORY ? (
                    <Route path={ROUTES.packrips} element={<PackRips />} />
                ) : null}
                {WAX_SIGN_IN && SHOW_CRAFT ? (
                    <Route path={ROUTES.craft} element={<Craft />} />
                ) : null}
                {WAX_SIGN_IN ? (
                    <Route path={ROUTES.resources} element={<Resources />} />
                ) : null}
                {WAX_SIGN_IN && FIAT_PAYMENT_ENABLED && SHOW_BUY_PAGE ? (
                    <Route path={ROUTES.paymenthistory} element={<FiatHistory />} />
                ) : null}
                <Route path={ROUTES.actOne} element={<ActOne />} />
                <Route path={ROUTES.actTwo} element={<ActTwo />} />
                <Route path={ROUTES.vivaPacks} element={<VivaPacks />} />
                <Route path={ROUTES.leaderboard} element={<Leaderboard />} />
                <Route path={ROUTES.privacypolicy} element={<PrivacyPolicy />} />
                <Route
                    path={ROUTES.termsandconditions}
                    element={<TermsAndConditions />}
                />
                <Route path={ROUTES.nftlicense} element={<License />} />
                <Route path={ROUTES.notfound} element={<NotFound />} />
                <Route path={ROUTES.notfound} />
            </Routes>
        </Container>
    );
}

export default WAX_SIGN_IN ? WithRouter(withUAL(Content)) : WithRouter(Content);
