/* eslint import/extensions: "off" */

import Button from '@components/Button';
import Container from '@components/Container';
import StyledFormModal from '@components/FormModal';
import Grid from '@components/Grid';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { IPFS_ENDPOINT } from '@utils/globals';
import RES from '@utils/resources';
import ROUTES from '@utils/routes';
import STRINGS from '@utils/strings';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import logo from '@images/logo.png';

const InfoContainer = styled.div(({ theme, imgToRender }) => ({
    background: `linear-gradient(0deg, rgba(6,2,1,0.7), rgba(6,2,1,0.7)), url(${imgToRender})`,
    backgroundSize: 'cover',
    backgroundPositionX: 'center',
    height: '100%',
    textAlign: 'center',
    justifyContent: 'center',
    paddingTop: theme.spacing.xl,
    marginBottom: theme.spacing.xl
}));

const LogoImage = styled.img(({ theme }) => ({
    objectFit: 'contain',
    width: '60vw',
    [theme.mediaQuery.tabletUp]: {
        width: '40vw'
    }
}));

const DarkGradientOverlay = styled.div(() => ({
    backgroundImage: `linear-gradient(rgba(6,2,1,0), rgba(6,2,1,1))`,
    width: '100%',
    height: '500px',
    marginTop: '-500px',
    userSelect: 'none'
}));

const Image = styled.img(({ theme }) => ({
    height: '50vh',
    maxHeight: '600px',
    width: '100%',
    objectFit: 'contain',
    [theme.mediaQuery.mobileOnly]: {
        maxHeight: '300px'
    }
}));

const EventTitle = styled.h1(({ theme }) => ({
    ...theme.typography.h1,
    textTransform: 'uppercase',
    color: theme.colors.common.text,
    fontWeight: 'bold',
    letterSpacing: '4px',
    textAlign: 'center'
}));

const EventSubtitle = styled.p(({ theme }) => ({
    fontSize: '24px',
    lineHeight: '160%',
    fontWeight: 400,
    color: theme.colors.common.text,
    letterSpacing: '4px',
    textAlign: 'center',
    textTransform: 'uppercase',
    textDecoration: 'line-through'
}));

const PointsTitle = styled.h3(({ theme }) => ({
    ...theme.typography.h3,
    color: theme.colors.common.text,
    fontWeight: 'bold',
    letterSpacing: '4px',
    textTransform: 'uppercase'
}));

const PointsDescription = styled.p(({ theme }) => ({
    fontSize: '24px',
    lineHeight: '160%',
    fontWeight: 400,
    color: theme.colors.common.text,
    letterSpacing: '2px'
}));

const PointsList = styled.ul(({ theme }) => ({
    fontSize: '20px',
    lineHeight: '160%',
    fontWeight: 400,
    color: theme.colors.common.text,
    listStyle: 'none',
    textAlign: 'left',
    padding: 0
}));

const PointDetails = styled.li(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing.xxl,
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    borderBottom: `1px solid ${theme.colors.common.lightBackground}`,
    padding: `${theme.spacing.xs} ${theme.spacing.xxs}`,
    'span:last-of-type': {
        whiteSpace: 'nowrap'
    }
}));

const PackDescription = styled.p(({ theme }) => ({
    ...theme.typography.p,
    color: theme.colors.common.text,
    fontWeight: 'bold'
}));

const LearnMore = styled.a(({ theme }) => ({
    color: theme.colors.primary.dark,
    backgroundColor: theme.colors.primary.light,
    padding: `${theme.spacing.xxs} ${theme.spacing.s}`,
    textAlign: 'center',
    textDecoration: 'none',
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: '1.125rem',
    letterSpacing: 3,
    opacity: 1,
    '&:hover': {
        background: `${theme.colors.primary.main} !important`
    }
}));

const RarityTitle = styled.h4(({ theme }) => ({
    ...theme.typography.h4,
    fontWeight: 'bold',
    letterSpacing: '4px',
    textTransform: 'uppercase',
    color: theme.colors.common.text
}));

const RarityDescription = styled.p(({ theme }) => ({
    ...theme.typography.p,
    color: theme.colors.common.text,
    fontWeight: 'bold',
    textAlign: 'center'
}));

function VivaPacks() {
    const theme = useTheme();
    const history = useNavigate();

    const [ showHelpModal, setShowHelpModal ] = useState(false);

    const backgroundImages = RES.images.homeBackgrounds;
    const [ imgToRender, setImgToRender ] = useState(backgroundImages[0]);

    useEffect(() => {
        setImgToRender(
            backgroundImages[Math.floor(Math.random() * backgroundImages.length)]
        );
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const CustomGrid = {
        margin: 0,
        gap: theme.spacing.m,
        img: {
            width: 'auto',
            objectFit: 'contain',
            maxHeight: '500px'
        },
        video: {
            width: 'auto',
            objectFit: 'contain',
            maxHeight: '500px'
        },
        [theme.mediaQuery.largeMobileUp]: {
            gridTemplateColumns: 'repeat(2, 1fr)'
        },
        [theme.mediaQuery.largeMobileOnly]: {
            gridTemplateColumns: 'repeat(1, 1fr)',
            img: {
                maxWidth: '80vw',
                height: 'auto'
            },
            video: {
                maxWidth: '80vw',
                height: 'auto'
            }
        }
    };

    const ContainerStyle = {
        margin: 'auto',
        maxWidth: '1000px',
        [theme.mediaQuery.largeMobileUp]: {
            padding: `${theme.spacing.xxxl} 0`
        },
        [theme.mediaQuery.mobileOnly]: {
            padding: `${theme.spacing.xl} 0 ${theme.spacing.xs} 0`
        }
    };

    return (
        <>
            <InfoContainer imgToRender={imgToRender}>
                <Container direction='column'>
                    <LogoImage alt={STRINGS.campaign} src={logo} />
                </Container>
                <DarkGradientOverlay />
            </InfoContainer>
            <Container
                direction='column'
                gap={theme.spacing.xxs}
                padding={`0 ${theme.spacing.s}`}
            >
                <EventTitle>{STRINGS.vivaPacksPage.title}</EventTitle>
                <EventSubtitle>{STRINGS.vivaPacksPage.subtitle}</EventSubtitle>
            </Container>
            <Container direction='row' style={ContainerStyle}>
                <Container direction='row' gap={theme.spacing.xxl}>
                    {RES.vivaPacks.map((pack, index) => (
                        <Container
                            key={index}
                            direction='column'
                            gap={theme.spacing.xs}
                            style={{ maxWidth: '500px' }}
                        >
                            <Image
                                src={`${IPFS_ENDPOINT}${pack.srcImg}`}
                                alt={pack.caption}
                            />
                            <Container direction='column' gap={theme.spacing.xxs}>
                                <PackDescription>{pack.description}</PackDescription>
                            </Container>
                        </Container>
                    ))}
                </Container>
                <Container
                    direction='column'
                    alignItems='center'
                    padding={`0 ${theme.spacing.xs}`}
                    margin={`${theme.spacing.xl} 0`}
                    gap={theme.spacing.m}
                >
                    <Container direction='column' gap={theme.spacing.xxs}>
                        <PointsTitle>{STRINGS.vivaPacksPage.howToEarn}</PointsTitle>
                        <PointsDescription>
                            {STRINGS.vivaPacksPage.pointsDescription}
                        </PointsDescription>
                    </Container>
                    <PointsList>
                        {STRINGS.vivaPacksPage.pointsList.map((point, index) => {
                            return (
                                <PointDetails key={index}>
                                    <span>{point.description}</span>
                                    <span>{point.points}</span>
                                </PointDetails>
                            );
                        })}
                    </PointsList>
                </Container>
            </Container>
            <Container
                direction='column'
                gap={theme.spacing.m}
                padding={`0 ${theme.spacing.s}`}
            >
                <RarityTitle>{STRINGS.vivaPacksPage.rarity}</RarityTitle>
                <Grid items={RES.vivaPacksSamples} style={CustomGrid} />
                <RarityDescription>
                    {STRINGS.vivaPacksPage.description}
                </RarityDescription>
            </Container>
            <Container
                direction='column'
                gap={theme.spacing.s}
                style={{
                    paddingTop: `${theme.spacing.xxl}`,
                    paddingBottom: `${theme.spacing.xxl}`
                }}
            >
                <LearnMore
                    href={RES.learnMoreAboutVivaPack.href}
                    target='_blank'
                    rel='noreferrer noopener'
                >
                    {RES.learnMoreAboutVivaPack.label}
                </LearnMore>
                <Button onClick={() => history.push(ROUTES.leaderboard)}>
                    {STRINGS.vivaPacksPage.viewLeaderboard}
                </Button>
            </Container>
            <Container padding={theme.spacing.s}>
                <Button onClick={() => setShowHelpModal(true)} secondary>
                    {STRINGS.help}
                </Button>
            </Container>
            <StyledFormModal
                show={showHelpModal}
                onClose={() => setShowHelpModal(false)}
                title={STRINGS.helpFormTitle}
                portalId='19612576'
                formId='6a9f8c33-f4fb-43fd-8b15-4fd2c0561566'
            />
        </>
    );
}

export default VivaPacks;
