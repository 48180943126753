import Footer from '@components/Footer';
import Header from '@components/Header';
import Content from '@context/Content';
import styled from '@emotion/styled';
import { AccountProvider } from '@hooks/AccountContext';
import { SHOW_HEADER } from '@utils/globals';
import { HashRouter as Router } from 'react-router-dom';

const AppContainer = styled.div(() => ({
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column'
}));

function App() {
    return (
        <Router>
            <AppContainer>
                <AccountProvider>
                    {SHOW_HEADER ? <Header /> : null}
                    <Content />
                    <Footer />
                </AccountProvider>
            </AppContainer>
        </Router>
    );
}

export default App;
