import {
    craft,
    getAssetId,
    getInfoFromTemplateId,
    getOpenedFromAsset,
    getTemplateIdFromAsset
} from '@api/api';
import Animation from '@components/Animation';
import Button from '@components/Button';
import ConfirmationModal from '@components/ConfirmationModal';
import Container from '@components/Container';
import CraftRecipeItem from '@components/CraftRecipeItem';
import ErrorModal from '@components/ErrorModal';
import Loading from '@components/Loading';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { IPFS_ENDPOINT } from '@utils/globals';
import ROUTES from '@utils/routes';
import STRINGS from '@utils/strings';
import { isCPUErrorMessage, isNETErrorMessage } from '@utils/utils';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { withUAL } from 'ual-reactjs-renderer';

const ComponentsContainer = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: theme.spacing.xs,
    width: '100%',
    alignItems: 'center'
}));

const Title = styled.h5(({ theme }) => ({
    ...theme.typography.h5,
    textAlign: 'center',
    color: theme.colors.common.text
}));

const Variant = styled.p(({ theme }) => ({
    ...theme.typography.p,
    textAlign: 'center',
    color: theme.colors.common.text
}));

const ButtonContainer = styled.div(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '60%'
}));

const Prize = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing.xs,
    alignItems: 'center',
    width: '100%'
}));

const PrizeImg = styled.img(() => ({
    width: '100%',
    objectFit: 'contain',
    maxWidth: '300px'
}));

const PrizeVideo = styled.video(() => ({
    width: '100%',
    objectFit: 'contain',
    maxWidth: '300px'
}));

const Line = styled.div(({ theme }) => ({
    width: '100%',
    borderBottom: `1px solid ${theme.colors.primary.main}`
}));

const CPUErrorMessage = styled.p(({ theme }) => ({
    ...theme.typography.p,
    margin: theme.spacing.s,
    textAlign: 'initial',
    a: {
        color: theme.colors.primary.main,
        '&:hover': {
            color: theme.colors.primary.dark
        }
    }
}));

function CraftRecipe({ recipe, inventory, ual }) {
    const theme = useTheme();
    const history = useNavigate();

    const ButtonStyle = {
        ...theme.typography.bubblegum,
        fontSize: 24
    };

    const initSelectedItems = () => {
        let initialSelectedItems = {};

        for (const templateId in input) {
            initialSelectedItems[templateId] = [];
        }

        return initialSelectedItems;
    };

    const [ loading, setLoading ] = useState(true);
    const [ selectedItems, setSelectedItems ] = useState(null);
    const [ isDisabled, setIsDisabled ] = useState(true);
    const [ outcome, setOutcome ] = useState(null);
    const [ input, setInput ] = useState(null);
    const [ errorMessage, setErrorMessage ] = useState('');
    const [ showCraftConfirmationModal, setShowCraftConfirmationModal ] =
    useState(false);
    const [ showAnimation, setShowAnimation ] = useState(false);

    let inputCopy = {};

    useEffect(() => {
        updateOutcome();
        updateNeededCards();
    }, []);

    useEffect(() => {
        if (outcome && input) {
            setSelectedItems(initSelectedItems());
            setLoading(false);
        }
    }, [ outcome, input ]);

    const updateNeededCards = async () => {
        Promise.all(
            recipe.recipeTemplatesId.map(async (templateId) => {
                if (inputCopy[templateId]) {
                    inputCopy[templateId].quantity += 1;
                } else {
                    inputCopy[templateId] = {};
                    inputCopy[templateId].quantity = 1;

                    await getInfoFromTemplateId(templateId, (inputInfo) => {
                        inputCopy[templateId] = Object.assign(
                            inputCopy[templateId],
                            inputInfo
                        );
                    });

                    let assets = inventory.filter((asset) => {
                        return (
                            parseInt(getTemplateIdFromAsset(asset)) === templateId &&
              getOpenedFromAsset(asset) !== 0
                        );
                    });

                    inputCopy[templateId] = Object.assign(inputCopy[templateId], {
                        assets: assets
                    });
                }
            })
        )
            .then(() => {
                setInput(inputCopy);
            })
            .catch((err) => {
                console.error(err);
                setLoading(false);
            });
    };

    const updateOutcome = () => {
        getInfoFromTemplateId(
            recipe.outcomeTemplateId,
            (outcomeInfo) => {
                setOutcome(outcomeInfo);
            },
            () => {
                setLoading(false);
            }
        );
    };

    const updateDisabledState = (selectedItems) => {
        let disabled = false;

        for (const templateId in input) {
            if (
                selectedItems[templateId] &&
        selectedItems[templateId].length !== input[templateId].quantity
            ) {
                disabled = true;
                break;
            }
        }

        setIsDisabled(disabled);
    };

    const updateRecipeItem = (templateId, recipeItem) => {
        let newSelectedItems = { ...selectedItems };
        newSelectedItems[templateId] = recipeItem;

        setSelectedItems(newSelectedItems);
        updateDisabledState(newSelectedItems);
    };

    const onCraft = () => {
        const success = () => {
            setShowCraftConfirmationModal(false);
            setShowAnimation(true);
            // setShowSuccessModal(true);
        };

        let ids = [];
        for (let templateId in selectedItems) {
            let aux = selectedItems[templateId].map((asset) => getAssetId(asset));
            ids = ids.concat(aux);
        }
        craft(ual.activeUser, ids, recipe.id, success, (errorMessage) =>
            setErrorMessage(errorMessage)
        );
    };

    const renderCraftItem = () => {
        let items = [];
        for (let key in input) {
            items.push(
                <CraftRecipeItem
                    key={key}
                    component={input[key]}
                    templateId={key}
                    selectedItems={selectedItems[key] ?? []}
                    updateRecipeItem={updateRecipeItem}
                />
            );
        }

        return items;
    };

    return !loading ? (
        <>
            <Container
                direction='row'
                margin={`${theme.spacing.xxl} ${theme.spacing.xs}`}
                gap={theme.spacing.l}
                alignItems='center'
                isFullWidth={false}
            >
                <ComponentsContainer>{renderCraftItem()}</ComponentsContainer>
                <ButtonContainer>
                    <Button disabled={isDisabled} style={ButtonStyle} onClick={onCraft}>
                        {STRINGS.craftButton}
                    </Button>
                </ButtonContainer>
                {outcome ? (
                    <Prize>
                        {outcome.isImg === true ? (
                            <PrizeImg
                                src={`${IPFS_ENDPOINT}${outcome.imgHash}`}
                                alt={outcome.name}
                            />
                        ) : (
                            <PrizeVideo muted autoPlay loop playsInline width='100%'>
                                <source
                                    src={`${IPFS_ENDPOINT}${outcome.imgHash}`}
                                    type='video/mp4'
                                />
                            </PrizeVideo>
                        )}
                        <Container direction='column' alignItems='center'>
                            <Title>{outcome.name}</Title>
                            <Variant>
                                {outcome.variant !== 'X-Ray' ? outcome.variant : null}
                            </Variant>
                        </Container>
                    </Prize>
                ) : null}
            </Container>
            <Line />
            <ConfirmationModal
                show={showCraftConfirmationModal}
                onConfirm={onCraft}
                onClose={() => setShowCraftConfirmationModal(false)}
                confirmationButtonLabel={STRINGS.craftPage.confirmationDescription}
                title={STRINGS.craftPage.confirmationTitle}
            />
            <ErrorModal
                show={errorMessage !== ''}
                onClose={() => setErrorMessage('')}
            >
                {errorMessage}
                {isCPUErrorMessage(errorMessage) ? (
                    <CPUErrorMessage>{STRINGS.cpuErrorMessage}</CPUErrorMessage>
                ) :
                    ''
                }
                {isNETErrorMessage(errorMessage) ? (
                    <CPUErrorMessage>{STRINGS.netErrorMessage}</CPUErrorMessage>
                ) :
                    ''
                }
            </ErrorModal>
            <Animation
                showAnimation={showAnimation}
                onAnimationEnd={() => {
                    setTimeout(() => {
                        setShowAnimation(false);
                        history.push(`${ROUTES.inventory}`);
                    }, 2000);
                }}
                identifier='craft'
            />
        </>
    ) : (
        <Loading />
    );
}

export default withUAL(CraftRecipe);
