import StyledModal from '@components/Modal';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import {
    isCheckboxChecked,
    isValidName,
    isValidTeam,
    isValidWaxWalletAddress
} from '@utils/formValidations';

const Title = styled.div(({ theme }) => ({
    ...theme.typography.h6,
    marginBottom: theme.spacing.m,
    display: 'flex',
    justifyContent: 'center',
    color: theme.colors.common.text
}));

const Help = styled.div(({ theme }) => ({
    ...theme.typography.p,
    marginTop: theme.spacing.s,
    color: theme.colors.common.text,
    a: {
        color: theme.colors.common.text
    },
    'a:hover': {
        color: theme.colors.primary.main,
        transition: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1)'
    }
}));

function validateForm() {
    const waxWalletAddress = document.querySelector(
        'input[name="wax_wallet_address"]'
    );
    const firstName = document.querySelector('input[name="firstname"]');
    const lastName = document.querySelector('input[name="lastname"]');
    const team = document.querySelector('input[name="team"]');
    const checkBoxList = document.querySelector('.inputs-list');

    team ? team.setAttribute('maxLength', '80!important') : '';

    document
        .querySelector('input[type="submit"]')
        .addEventListener('click', (event) => {
            let formValidated = false;

            const results = [
                waxWalletAddress
                    ? {
                        element: waxWalletAddress,
                        isValid: isValidWaxWalletAddress(waxWalletAddress.value),
                        message: 'Invalid Wax Wallet Address',
                        type: 'input'
                    }
                    : null,
                firstName
                    ? {
                        element: firstName,
                        isValid: isValidName(firstName.value),
                        message: 'Invalid first name',
                        type: 'input'
                    }
                    : null,
                lastName
                    ? {
                        element: lastName,
                        isValid: isValidName(lastName.value),
                        message: 'Invalid last name',
                        type: 'input'
                    }
                    : null,
                team
                    ? {
                        element: team,
                        isValid: isValidTeam(team.value),
                        message: 'Invalid team',
                        type: 'input'
                    }
                    : null,
                checkBoxList
                    ? {
                        element: checkBoxList,
                        isValid: isCheckboxChecked(checkBoxList),
                        message: 'Check at least one option',
                        type: 'checkbox'
                    }
                    : null
            ];

            const filteredResults = results.filter((item) => item !== null);

            if (filteredResults) {
                for (const item of filteredResults) {
                    const error =
            item.element.parentElement.querySelector('.modalErrorMsg');

                    if (!item.isValid && !error) {
                        const notification = document.createElement('span');

                        item.element.style.marginBottom = '4px';
                        item.element.classList.add('modalError');
                        notification.classList.add('modalErrorMsg');
                        notification.style.display = 'block';
                        notification.textContent = item.message;
                        item.element.insertAdjacentElement('afterend', notification);

                        item.element.addEventListener(
                            item.type === 'input' ? 'keyup' : 'change',
                            () => {
                                notification.remove();
                                item.element.classList.remove('modalError');
                                item.element.removeAttribute('style');
                            }
                        );
                    }
                }

                const validatedFields = filteredResults.filter(
                    (item) => item.isValid === true
                );

                if (validatedFields.length === filteredResults.length) {
                    formValidated = true;
                }
            }

            if (!formValidated) {
                event.preventDefault();
            }
        });
}

function StyledFormModal({
    show,
    onConfirm,
    onClose,
    title,
    style,
    portalId,
    formId,
    help,
    ...otherProps
}) {
    const theme = useTheme();

    const hbspotStyle = {
        position: 'relative',
        maxHeight: '100vh !important',
        overflowY: 'scroll !important',

        '.modalForm': {
            display: 'flex !important',
            flexDirection: 'column !important',
            alignItems: 'center !important',
            marginTop: `24px !important`,
            minHeight: '250px !important'
        },

        '.modalForm p': {
            color: '#D3A145 !important',
            fontSize: '0.8rem !important',
            fontWeight: '300 !important',
            lineHeight: '150% !important',
            textAlign: 'left'
        },

        '.modalForm label': {
            marginBottom: 0,
            display: 'block !important',
            width: '100% !important',
            textAlign: 'left !important',
            fontWeight: 400,
            color: '#D3A145'
        },

        '.modalForm .field': {
            marginBottom: '1rem !important',
            width: '100% !important',
            maxWidth: '400px'
        },

        '.modalForm input': {
            border: '1px solid #D3A145 !important',
            backgroundColor: 'transparent !important',
            borderRadius: '3px !important',
            fontSize: '1rem !important',
            padding: '5px !important',
            color: '#F0C74B !important',
            width: '100% !important',
            outline: 'none !important',
            transition: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1) !important'
        },

        '.modalForm input:placeholder': {
            color: '#F0C74B !important',
            opacity: '0.5 !important'
        },

        '.modalForm input:active': {
            border: '1px solid #F0C74B !important'
        },
        '.modalForm input:focus': {
            border: '1px solid #F0C74B !important'
        },
        '.modalForm ul': {
            listStyle: 'none',
            paddingLeft: 0
        },
        '.modalForm li label': {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center'
        },
        '.modalForm li label input': {
            width: '20px !important',
            marginRight: 5
        },

        'input.modalButton': {
            transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1) !important',
            transitionDuration: '150ms !important',
            color: '#D3A145 !important',
            backgroundColor: '#A4230D !important',
            padding: '4px 24px !important',
            outline: 'none !important',
            fontWeight: '400 !important',
            fontSize: '18px !important',
            lineHeight: '160% !important',
            borderRadius: '0.25rem !important',
            cursor: 'pointer !important',
            marginBottom: '1rem !important',
            border: 'none !important'
        },

        'input.modalButton:hover': {
            backgroundColor: '#88070F !important',
            color: '#D3A145 !important'
        },

        'input.modalError': {
            border: '1px solid #CA1515 !important'
        },

        '.modalErrorMsg': {
            fontFamily: '"Poppins", sans-serif !important',
            listStyle: 'none !important',
            color: '#CA1515 !important',
            fontWeight: 500,
            fontSize: '1rem !important',
            marginTop: '0 !important',
            paddingLeft: '0 !important',
            textAlign: 'left'
        },

        '.modalForm textarea': {
            border: '1px solid #D3A145 !important',
            backgroundColor: 'transparent !important',
            borderRadius: '3px !important',
            fontSize: '1rem !important',
            padding: '5px !important',
            color: '#F0C74B !important',
            width: '100% !important',
            outline: 'none !important',
            transition: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1) !important'
        },

        '.modalForm .hs-error-msg': {
            color: '#CA1515 !important'
        }
    };

    return (
        <>
            <StyledModal show={show} onClose={onClose} style={hbspotStyle}>
                <Title>{title}</Title>
                {/* <HubspotForm
                portalId={portalId}
                formId={formId}
                onSubmit={() => { return (<p>{STRINGS.formSuccessMessage}</p>);}}
                loading={<Loading />}
                cssClass="modalForm"
                errorMessageClass="modalErrorMsg"
                errorClass="modalError"
                submitButtonClass="modalButton"
                onReady={validateForm}
            /> */}
                {help ? <Help>{help}</Help> : null}
            </StyledModal>
        </>
    );
}

export default StyledFormModal;
